@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
    font-family: sans-serif;
}

.test {
    color: aliceblue;
} */